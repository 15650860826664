import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import * as jwt_decode from 'jwt-decode';


interface JwtPayload {
  exp: number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private router: Router) {}

  isAuthenticated(): Observable<boolean> {
    const token = localStorage.getItem('accessToken');
    return of(!!token);
  }

  logout() {
    localStorage.removeItem('accessToken');
    localStorage.clear()
    this.router.navigate(['/login']);
  }

  private base64UrlDecode(base64Url: string): string {
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const decoded = atob(base64);
    try {
      return decodeURIComponent(escape(decoded));
    } catch (e) {
      return decoded;
    }
  }
  
  getTokenExpirationDate(token: string): Date | null {
    try {
      const parts = token.split('.');
      if (parts.length !== 3) {
        throw new Error('JWT does not have 3 parts');
      }

      const decoded = this.base64UrlDecode(parts[1]);
      const payload = JSON.parse(decoded);

      if (!payload.exp) {
        return null;
      }

      const date = new Date(0);
      date.setUTCSeconds(payload.exp);
      return date;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }

  isTokenExpired(token: string): boolean {
    const expirationDate = this.getTokenExpirationDate(token);
    if (!expirationDate) {
      return true;
    }

    return expirationDate < new Date();
  }
}
