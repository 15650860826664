<c-sidebar
  #sidebar1="cSidebar"
  class="d-print-none sidebar sidebar-fixed border-end"
  colorScheme="dark"
  id="sidebar1"
  visible
>
  <c-sidebar-header class="border-bottom">
    <c-sidebar-brand [routerLink]="[]">
      <img src="assets/images/pass-logo.png" class="sidebar-brand-narrow" height="30" style="border-radius: 50%;"/>
      <img src="assets/images/pass-logo.png" class="sidebar-brand-full" height="30" style="border-radius: 50%;"/>
      <span class="logo-title sidebar-brand-full">NIMBUZ</span>
    </c-sidebar-brand>
  </c-sidebar-header>

  <ng-scrollbar #scrollbar="ngScrollbar" (updated)="onScrollbarUpdate(scrollbar.state)" class="overflow" pointerEventsMethod="scrollbar" visibility="hover">
    <c-sidebar-nav #overflow [navItems]="navItems" dropdownMode="close" compact />
  </ng-scrollbar>
  @if (!sidebar1.narrow) {
    <c-sidebar-footer cSidebarToggle="sidebar1" class="border-top d-none d-lg-flex" toggle="unfoldable" style="cursor: pointer;">
      <button cSidebarToggler></button>
    </c-sidebar-footer>
  }
</c-sidebar>

<div class="wrapper d-flex flex-column min-vh-100">
  
  <app-default-header [cShadowOnScroll]="'sm'"
                      class="mb-4 d-print-none header header-sticky p-0 shadow-sm"
                      position="sticky"
                      sidebarId="sidebar1" />
  
  <div class="body flex-grow-1">
    <c-container breakpoint="lg" class="h-auto px-4">
      <router-outlet />
    </c-container>
  </div>

</div>

