import { Component, computed, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import {
  AvatarComponent,
  ColorModeService,
  ContainerComponent,
  DropdownComponent,
  DropdownDividerDirective,
  DropdownHeaderDirective,
  DropdownItemDirective,
  DropdownMenuDirective,
  DropdownToggleDirective,
  HeaderComponent,
  HeaderNavComponent,
  HeaderTogglerDirective,
  NavItemComponent,
  NavLinkDirective,
  SidebarToggleDirective,
  TextColorDirective,
  ThemeDirective
} from '@coreui/angular';
import { CommonModule, NgTemplateOutlet,NgForOf, NgIf, NgStyle } from '@angular/common';
import { ActivatedRoute, RouterLink, RouterLinkActive, NavigationEnd, Router } from '@angular/router';
import { IconDirective } from '@coreui/icons-angular';
import { AuthService } from 'src/app/services/auth.service';

import { ListItem } from 'src/app/shared/modal/list-item.model';
import { SharedService } from '../../../services/shared.service';
import { DeploymentsService } from 'src/app/services/deployments.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['./default-header.component.scss'],
  standalone: true,
  imports: [ContainerComponent, HeaderTogglerDirective, SidebarToggleDirective, AvatarComponent,
    IconDirective, HeaderNavComponent, NavItemComponent, NavLinkDirective, RouterLink,
    RouterLinkActive, NgTemplateOutlet, ThemeDirective,
    DropdownComponent, DropdownToggleDirective, TextColorDirective,
    DropdownMenuDirective, DropdownHeaderDirective, DropdownItemDirective,DropdownDividerDirective, CommonModule],
  providers: [DeploymentsService]
})
export class DefaultHeaderComponent extends HeaderComponent implements OnInit {

  readonly #colorModeService = inject(ColorModeService);
  readonly colorMode = this.#colorModeService.colorMode;

  readonly colorModes = [
    { name: 'light', text: 'Light', icon: 'cilSun' },
    { name: 'dark', text: 'Dark', icon: 'cilMoon' }
  ];

  readonly icons = computed(() => {
    const currentMode = this.colorMode();
    return this.colorModes.find(mode => mode.name === currentMode)?.icon ?? 'cilSun';
  });

  listOfEnviroinments:ListItem[] = [];
  selectedEnvironment: string = '';
  routeName= localStorage.getItem('routeName') === "Login Page" ? 'Deployments' : localStorage.getItem('routeName');

  constructor(private authService: AuthService,
    private sharedService: SharedService, private http: DeploymentsService,private activatedRout:ActivatedRoute, private route:Router
  ) {
    super();
    
  }

  @Input() sidebarId: string = 'sidebar1';

  ngOnInit(): void {
    const environment = this.sharedService.getCookie('environment')
    
    this.http.getEnvironment().subscribe((res: any) => {
      this.listOfEnviroinments = res?.data;
      if (environment) {
        this.selectedEnvironment = JSON.parse(environment)?.name;
      }else{
        this.getSelectedEnv(res?.data[0])
      }
    })

    this.route.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.routeName = this.getRouteName(this.activatedRout.root);
      localStorage.setItem('routeName',this.getRouteName(this.activatedRout.root));
    });
    
  }

  private getRouteName(route: ActivatedRoute): string {
    if (route.firstChild) {
      return this.getRouteName(route.firstChild);
    }
    return route.snapshot.data['title'];
  }


  setTheme(color: any) {
    this.sharedService.setCookie('theme', color,10)
    this.colorMode.set(color);
    this.sharedService.emitValueChange(color);
    this.#colorModeService.setStoredTheme('selectedTheme', color);
  }
  getSelectedEnv(env: any) {
    this.sharedService.setCookie('environment', JSON.stringify(env),10)
    this.sharedService.emitEnvValueChange(env)
    this.selectedEnvironment = env?.name;
    // this.route.navigate(['/dashboard']);
  }
  logout() {
    this.authService.logout();
  }
  isSelected(item: any): boolean {
    return this.selectedEnvironment === item.name;
  }
}
